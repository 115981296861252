























import { defineComponent } from '@nuxtjs/composition-api';

interface VideoSource {
  id: string;
  filename: string;
}

interface Props extends Blok {
  source: VideoSource[];
  autoplay: boolean;
  controls: boolean;
  loop: '1' | '0';
  aspectRatio: '1/1' | '16/9';
  width: number,
  height: number
}

export default defineComponent({
  props: {
    blok: { type: Object as () => Props, required: true },
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--video-aspect-ratio': this.blok.aspectRatio,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      const video : any = this.$refs.video;
      video?.play();
    }, 3000);
  },
});
